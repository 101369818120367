var app = {
	windowLoaded: function () {
		_main = this;
		time = $('.guest').length?2000:400;
		setTimeout(function(){
			$('.preloader').fadeOut(500, function () {
				// $(this).remove();
			});
		}, time);		
	},
	windowResized: function () {
		_main = this;
		_main.getViewport();
	},
	windowScrolled: function () {
		_main = this;

		if (_main.scrollIcon) {
			
			var scrollPercent = 100 * $(window).scrollTop() / ($(document).height() - $(window).height());
			
			// console.log('percent: ',scrollPercent);

			if (scrollPercent > 70) {
				$('.icon-scroll-down').removeClass('show');
			} else {
				$('.icon-scroll-down').addClass('show');
			}
		}
	},
	cookies: {		
		createCookie: function(name, value, days) {		    
		    _main=app;
		    var date = new Date();
		    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
		    var expires = "; expires=" + date.toGMTString();
		    document.cookie = name + "=" + value + expires + "; path=/";
		    (_main.debug)?console.log('creating cookie: ',name):'';
		},
		readCookie: function(name) {
		    _main=app;
		    var nameEQ = name + "=";
		    var ca = document.cookie.split(';');
		    for (var i = 0; i < ca.length; i++) {
		    	var c = ca[i];
		    	while (c.charAt(0) == ' ') c = c.substring(1, c.length);
		    	if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
		    }
		    return null;
		},
		checkCookies: function() {
		    _main=app;
		    if (_main.cookies.readCookie(_main.cookieName) != 1) {
		      (_main.debug)?console.log('cookie %s is not created yet',_main.cookieName):'';
		      _main.cookies.notification('show');
		    } else {
		    	(_main.debug)?console.log('cookie %s already exists',_main.cookieName):'';
		    }
		    $('.closeNotification').on('click',function () {
		      _main.cookies.createCookie(_main.cookieName, 1, 365);
		      _main.cookies.notification('hide');
		    });
		},
		notification: function(state) {
			_main=app;
			switch (state) {
				case 'show':
					$('footer').append('<div class="cookiesNotification"><p>Na stronie używamy plików cookies.</p><p class="closeNotification">OK &times;</p></div>');
				break;
				case 'hide':					
					$('.cookiesNotification').fadeOut(300, function () {
				        $(this).remove();
				      });
				break;
			}
		}
	},	
	handleSliders: function() {
		if ($('.home-realizations-slider').length) {
			$('.home-realizations-slider').slick({
				arrows: false,
				autoplay: true,
				pauseOnFocus: false,
				pauseOnHover: false
			});
		}
		if ($('.portfolio-slider').length) {
			$('.portfolio-slider').slick({
				arrows: false,
				autoplay: true,
				pauseOnFocus: false,
				pauseOnHover: false
			});
		}
	},
	events: function () {
		_main = this;
		
		$('.pd').on('click', function(e) {
			e.preventDefault();
		});

		if (_main.viewport == 'mobile') {
			if ( $(window).height() < $('main').height() ) {
				_main.debug?console.log('content bigger than viewport'):'';
				$('.icon-scroll-down').addClass('show');
				_main.scrollIcon = true;
			} else {
				_main.debug?console.log('content smaller than viewport'):'';
			}
		}

		$('.sub-menu li').each(function() {
			if ($(this).hasClass('current_page_item')) {								
				$(this).parent('.sub-menu').addClass('show');
			}
			if ($(this).hasClass('current-menu-item')) {								
				$(this).parent('.sub-menu').addClass('show');
			}
		});

		// if ($('.sub-menu li').hasClass('current_page_item')) {
			
		// }

		$('.yearchange').on('click', function(e) {
			e.preventDefault();
			if ($(this).hasClass('left')) {
				var changeto = parseInt($('#selected-year').text()) - 1;
				$('#selected-year').text(changeto);
				$('.months .month').attr('data-year',changeto);
			} else if ($(this).hasClass('right')) {
				var changeto = parseInt($('#selected-year').text()) + 1;
				$('#selected-year').text(changeto);
				$('.months .month').attr('data-year',changeto);
			}
		});

		$('.months .month').on('click', function(e) {
			e.preventDefault();
			var year = $(this).attr('data-year');
			var month = $(this).attr('data-month');
			window.location.replace(base_url+'/?y='+year+'&month='+month);
		});

		$('.external').on('click', function(e) {
			e.preventDefault();
			target = $(this).find('a').attr('href');						
			var win = window.open(target, '_blank');
			if (win) {
				//Browser has allowed it to be opened
				win.focus();
			} else {
				//Browser has blocked it
				window.location(target);
			}
		});

		$('.current-month.trigger').on('click', function() {
			$('.select-date').toggleClass('show');
			$('.overlay').toggleClass('active');
		});

		$('.harmo .title').on('click', function() {
			// $(this).toggleClass('active').next('.full').toggleClass('show');
			$(this).toggleClass('active').next('.full').slideToggle();			
		});

		$('.side-menu .parent a').on('click', function(e) {
			_main.debug?console.log('reveal sub menu'):'';
			if ($(this).attr('href') == '#') {
				e.preventDefault();
				// $(this).next('.sub-menu').toggleClass('reveal');
				$(this).next('.sub-menu').slideToggle();
				$(this).parent('.parent').toggleClass('open')
				
			}
		});
		// $('.side-menu .parent.menu-item-has-children').on('click', function(e) {
		// 	_main.debug?console.log('reveal sub menu'):'';			
		// 	e.preventDefault();
		// 	// $(this).find('.sub-menu').toggleClass('reveal');
		// 	$(this).find('.sub-menu').slideToggle();
		// 	$(this).toggleClass('open');			
		// });

		$('.hamburger').on('click', function() {
			$(this).toggleClass('active');	
			$('.mobile-nav').toggleClass('active');	
			$('body').toggleClass('has-mobile-nav');
		});

		$('.search-trigger').on('click', function() {
			$("html, body").animate({
				scrollTop: 0
			}, 250);
			$('.search-box').toggleClass('reveal');
		});
	},
	getViewport: function () {
		_main = this;
		wh = $(window).width();
		if (wh <= _main.breakpointMobile) {
			_main.viewport = 'mobile';
		} else if (wh > _main.breakpointMobile && wh <= _main.breakpointTablet) {
			_main.viewport = 'tablet';
		} else {
			_main.viewport = 'desktop';
		}
		(_main.debug) ? console.log('viewport: ', _main.viewport) : '';		
	},
	init: function () {
		_main = this;
		_main.getViewport();
		_main.events();					
		_main.cookies.checkCookies();
	},

	debug: true,
	viewport: null,
	globalNavState: false,
	breakpointMobile: 640,
	breakpointTablet: 1100,
	scrollIcon: false,
	cookieName: 'instytutr21'
}

$(document).ready(function () {
	app.init();
});

$(window).on('load', function () {
	app.windowLoaded();
});

$(window).resize(function () {
	app.windowResized();
});

$(window).scroll(function () {	
	app.windowScrolled();
});
